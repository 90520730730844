import { Input } from '@enterprise-ui/canvas-ui-react'
import { FileView } from '@galleryjs/api-digital-assets'
import { getExtionsionlessGalleryName } from '../../util/galleryUtil'

interface GalleryAssetListItemProps {
  file?: FileView
  enableCheckBox: boolean
  enableSelect?: boolean
  checked?: boolean
  selected?: boolean
  onCheck?: (file: FileView) => void
  onClick?: (file: FileView) => void
  children?: React.ReactNode
  showcase?: boolean
  enableLightbox?: boolean
}

export const GalleryAssetListItem = ({
  file,
  enableCheckBox,
  enableSelect,
  checked,
  selected,
  onCheck,
  onClick,
  showcase,
  enableLightbox = false,
}: GalleryAssetListItemProps) => {
  if (file === undefined) {
    return <></>
  }

  // do not allow enabling fullscreen on the images if selecting images is enabled
  if (enableLightbox && enableSelect) {
    enableLightbox = false
  }

  let fullFileName = `${getExtionsionlessGalleryName(file.name)}.${
    [...file.fileSources]?.[0]?.fileExtension
  }`

  return (
    <>
      {enableCheckBox && (
        <div
          data-test-id="asset-checkbox"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            if (onCheck) {
              onCheck(file)
            }
            if (onClick && enableSelect) {
              onClick(file)
            }
          }}
        >
          <Input.Checkbox
            id="asset-checkbox"
            checked={checked}
            label={fullFileName}
            className="hc-mt-dense hc-ml-dense"
          />
        </div>
      )}
    </>
  )
}

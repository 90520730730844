import {
  Environment,
  Network,
  RecordSource,
  MissingFieldHandler,
  IEnvironment,
} from 'relay-runtime'

import RelayModernStore from 'relay-runtime/lib/store/RelayModernStore'
import useFetchGraphQL from './useFetchGraphQL'
import { useSubscribe } from './useSubscriptionClient'

const globalStore = new RelayModernStore(new RecordSource())

const missingFieldHandlers: MissingFieldHandler[] = [
  {
    handle(field: any, record: any, argValues: any): string | null | undefined {
      if (record != null && argValues.flowId) {
        return Buffer.from(
          JSON.stringify(['flows', argValues.flowId]),
        ).toString('base64')
      }
      if (record != null && field.name === 'flow' && argValues.rowId) {
        return Buffer.from(JSON.stringify(['flows', argValues.rowId])).toString(
          'base64',
        )
      }
      if (record != null && field.name && argValues.rowId) {
        // If field is user(rowId $rowId), convert to id and lookup
        return Buffer.from(
          JSON.stringify([`${field.name}s`, argValues.rowId]),
        ).toString('base64')
      }
      return
    },
    kind: 'linked',
  },
]

export default function useGwtRelayEnvironment() {
  const fetchGraphQL = useFetchGraphQL()

  const subscribe = useSubscribe()

  return new Environment({
    network: Network.create(fetchGraphQL, subscribe),
    store: globalStore,
    missingFieldHandlers,
  }) as IEnvironment
}

import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider, AuthProviderProps } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import './globalStyles.scss'
import { Main } from './views/Main'
import { QueryClient, QueryClientProvider } from 'react-query'
import { library } from '@fortawesome/fontawesome-svg-core'
import { ErrorBoundary } from '@praxis/component-logging'
import { ApiErrorPage } from './views/ApiErrorPage/ApiErrorPage'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { config as analyticsConfig } from '@praxis/component-analytics'

import {
  faEllipsisV,
  faTrash,
  faClock,
  faCheck,
  faTimes,
  faDownload,
  faFilter,
  faSortAmountDown,
  faChevronRight,
  faChevronLeft,
  faExclamationCircle,
  faGripVertical,
  faPlusCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { GwtRelayEnvironmentProvider } from './globalComponents/GwtRelayEnvironmentProvider'
import { GwtAuthCookie } from './globalComponents/GwtAuthCookie'

library.add(faEllipsisV)
library.add(faTrash)
library.add(faClock)
library.add(faCheck)
library.add(faTimes)
library.add(faDownload)
library.add(faFilter)
library.add(faSortAmountDown)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faExclamationCircle)
library.add(faGripVertical)
library.add(faPlusCircle)
library.add(faInfoCircle)

const App = () => {
  // Create a client

  const queryClient = new QueryClient()
  const options = queryClient.defaultQueryOptions({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: process.env.REACT_APP_ENV === 'dev' ? Infinity : Infinity,
  })
  const defaultOps = queryClient.getDefaultOptions()
  defaultOps.queries = options
  queryClient.setDefaultOptions(defaultOps)

  return (
    <EnvProvider
      configPath={
        process.env.REACT_APP_ENV === 'dev'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {({
        env,
      }: {
        env: {
          authConfig: AuthProviderProps
          authConfig_pol: AuthProviderProps
          metrics: { appName: string; apiKey: string }
        }
      }) => {
        const hostname = window?.location?.hostname
        let config = hostname.includes('partnersonline')
          ? { ...env.authConfig_pol }
          : { ...env.authConfig }

        return (
          <AuthProvider {...config}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <QueryClientProvider client={queryClient}>
              <GwtAuthCookie>
                <GwtRelayEnvironmentProvider>
                  <ErrorBoundary FallbackComponent={ApiErrorPage}>
                    <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
                    <Router>
                      <AnalyticsProvider
                        eventManagerConfiguration={{
                          ...env.metrics,
                          url: getAnalyticsUrl(
                            hostname.includes('partnersonline'),
                            process.env.REACT_APP_ENV === 'prod',
                          ),
                        }}
                      >
                        <Main />
                      </AnalyticsProvider>
                    </Router>
                  </ErrorBoundary>
                </GwtRelayEnvironmentProvider>
              </GwtAuthCookie>
            </QueryClientProvider>
          </AuthProvider>
        )
      }}
    </EnvProvider>
  )
}

const getAnalyticsUrl = (pol: boolean, prod: boolean): string => {
  if (pol) {
    if (prod) {
      return analyticsConfig.api.external.prod
    } else {
      return 'http://127.0.0.1:8080/analytics'
    }
  } else {
    if (prod) {
      return analyticsConfig.api.internal.prod
    } else {
      return 'http://127.0.0.1:8080/analytics'
    }
  }
}

export default App

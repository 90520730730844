import { atom, PrimitiveAtom } from 'jotai'
import { NotificationUpdate } from '../globalComponents/NotificationSubscription'
import { COMPONENT } from '../hooks'
import { UUID } from '../react-app-env'
import { PolFileUploadStatus } from '../hooks/__generated__/useNotificationsMutation.graphql'
import { ModuleGroupFilter } from '../views/GroupTemplates/GroupTemplateModules/__generated__/GroupTemplateModulesTablePaginationQuery_Refetchable.graphql'

export const preloadedReviewerCommentAtom = atom('')
export const titleAtom = atom('')
export const commentBoxAtom = atom(false)
export const dropFilesAtom = atom(false)
export const editCommentAtom = atom<UUID>('')

export type FeedbackType = 'ISSUE' | 'FEEDBACK'
export type FeedbackAtom = {
  visible: boolean
  type: FeedbackType
}

export type UploadFile = {
  galleryAssetId: UUID
  fileName: string
  tossUrl: string
  uploadError: string
  uploadStatus: PolFileUploadStatus
  rowId: UUID
}
type IdList = readonly UUID[]

export const uploadsAtom = atom<UploadFile[]>([])

export const feedbackAtom = atom<FeedbackAtom>({
  visible: false,
  type: 'ISSUE',
})

export const helpAtom = atom(false)

export type NotificationAtom = {
  current: number
  lastUpdated: number
  response: NotificationUpdate
  shouldUpdate: (notification: NotificationUpdate) => boolean
}

export const GLOBAL_REFETCH_ATOM_MAP: Map<
  string,
  PrimitiveAtom<NotificationAtom>
> = new Map()

const ZERO_STATE_ATOM = atom<NotificationAtom>({
  current: 0,
  lastUpdated: 0,
  response: {} as NotificationUpdate,
  shouldUpdate: () => false,
})

// Atoms for refetching queries
export const reloadAdminStatusCardAtom = atom(0)
export const reloadAdminTemplatesCardAtom = atom(0)

export type BannerAtomType =
  | {
      notificationText: string
      bannerBackgroundColor: string
      bannerTextColor: string
    }
  | undefined

export const bannerAtom = atom<BannerAtomType>(undefined)

export const getOrCreateGlobalRefetchAtom = (
  key: COMPONENT,
  uniqueId: string,
  shouldUpdate: (notification: NotificationUpdate) => boolean,
) => {
  if (uniqueId.includes('undefined') || uniqueId.includes('null')) {
    // no subscriptions if there is undefined or null in the key
    return ZERO_STATE_ATOM
  }

  const uKey = key + uniqueId

  if (!GLOBAL_REFETCH_ATOM_MAP.has(uKey)) {
    const globalRefetchAtom = atom<NotificationAtom>({
      current: 0,
      lastUpdated: 0,
      response: {} as NotificationUpdate,
      shouldUpdate,
    })
    GLOBAL_REFETCH_ATOM_MAP.set(uKey, globalRefetchAtom)
  }
  return GLOBAL_REFETCH_ATOM_MAP.get(uKey) || ZERO_STATE_ATOM
}

export const globalNotificationAtom = atom(
  null,
  (get, set, notification: NotificationUpdate) => {
    GLOBAL_REFETCH_ATOM_MAP.forEach((atom) => {
      const globalAtom = get(atom)
      if (globalAtom.shouldUpdate(notification)) {
        set(atom, {
          ...globalAtom,
          current: globalAtom.current + 1,
          response: { ...notification },
        })
      }
    })
  },
)

// Discussion Card Local Storage Atom
const atomWithLocalStorage = (key: string, initialValue: Map<UUID, number>) => {
  const getInitialValue = () => {
    const item = window.localStorage.getItem(key)
    if (item !== null) {
      try {
        return new Map<UUID, number>(JSON.parse(item))
      } catch (e) {
        // reset the map on error
        console.error(e)
        return new Map<UUID, number>()
      }
    }
    return initialValue
  }

  const baseAtom = atom<Map<UUID, number>>(getInitialValue()) as PrimitiveAtom<
    Map<UUID, number>
  >

  return atom(
    (get) => get(baseAtom),
    (get, set, flowUpdate: { flowId: UUID; date: number }) => {
      const dateMap = get(baseAtom)
      dateMap.set(flowUpdate.flowId, flowUpdate.date)
      set(baseAtom, dateMap)
      window.localStorage.setItem(
        key,
        JSON.stringify(Array.from(dateMap.entries())),
      )
    },
  )
}

const listAtomWithLocalStorage = (key: string, initialIdList: IdList) => {
  const getInitialValue = (): IdList => {
    const item = window.localStorage.getItem(key)
    if (item !== null) {
      try {
        return JSON.parse(item)
      } catch (e) {
        console.error(e)
        // reset the map on error
        return []
      }
    }
    return initialIdList
  }

  const baseAtom = atom<IdList>(getInitialValue()) as PrimitiveAtom<IdList>

  return atom(
    (get) => get(baseAtom),
    (get, set, update: IdList) => {
      set(baseAtom, update)
      window.localStorage.setItem(key, JSON.stringify(update))
    },
  )
}

//Map of flow_id to last seen date
export const discussionAtom = atomWithLocalStorage('gwt_discussions', new Map())

export const feedbackLastSeenAtom = listAtomWithLocalStorage(
  'feedback_last_seen',
  [],
)
export const feedbackCurrentAtom = listAtomWithLocalStorage(
  'feedback_current',
  [],
)

export const deletedCommentIdAtom = atom('')
export const deletedCommentFlowIdAtom = atom('')

//Global images carousel atom

export type ImageInfo = {
  src: string
  title?: string
}
export type GlobalImagesAtom = {
  images: ImageInfo[]
  enabled: boolean
}
export const globalImagesAtom = atom<GlobalImagesAtom>({
  images: [],
  enabled: false,
})

export const moduleGroupSearchAtom = atom<ModuleGroupFilter>({
  flow: {
    name: { likeInsensitive: null },
  },
})

export const documentScrollPercentAtom = atom<number>(0)
